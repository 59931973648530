import clsx from 'clsx';
import React, { ComponentPropsWithoutRef } from 'react';
import Avatar from './avatar';
import logo from '/public/bot.png';

const Message: React.FC<
  ComponentPropsWithoutRef<'div'> & { avatar?: React.ReactNode }
> = ({ children, avatar, className, ...rest }) => {
  return (
    <div
      className={clsx(
        'flex w-full border-b border-b-gray-200 px-6 py-4 justify-center even:bg-gray-50',
        className
      )}
      {...rest}
    >
      <div className="flex gap-4 w-full max-w-2xl">
        <div className="w-8 min-w-[32px]">{avatar}</div>
        <div className="leading-8 flex-1">{children}</div>
      </div>
    </div>
  );
};

const BotMessage: React.FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
}) => (
  <Message avatar={<Avatar src={logo.src} alt="Provectus Bot" />}>
    {children}
  </Message>
);

const Content: React.FC<ComponentPropsWithoutRef<'div'>> = ({ children }) => (
  <div className="flex flex-col w-full md:overflow-y-auto">{children}</div>
);

const Sidebar: React.FC<ComponentPropsWithoutRef<'div'>> = ({ children }) => (
  <div
    className={clsx([
      'border-gray-300 p-2 flex flex-col gap-2 ',
      'w-full md:border-r lg:max-w-xl md:overflow-y-auto',
    ])}
  >
    {children}
  </div>
);

const Chat: React.FC<ComponentPropsWithoutRef<'div'>> = ({ children }) => (
  <div className={clsx(['min-w-full h-[calc(100vh-64px)] border', 'md:flex'])}>
    {children}
  </div>
);

export default Object.assign(Chat, {
  Sidebar,
  Content,
  BotMessage,
  Message,
});
