import { trpc } from 'utils/trpc';
import Chat from 'components/chat';
import PeerCard from 'widgets/peer-card';
import { useBoolean } from 'lib/hooks/use-boolean';
import { orderBy } from 'lodash';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { RocketIcon } from 'lucide-react';

const PeerChatLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const peerId = router.query.id as string;

  const { data: peers = [], isSuccess } = trpc.user.peers.useQuery();
  const [inProgressOnly, setInProgressOnly] = useBoolean(true);
  const inProgressPeers = peers.filter(({ status }) => status !== 'COMPLETED');
  const hasCompleted = inProgressPeers.length !== peers.length;

  const filteredPeers = orderBy(
    inProgressOnly
      ? peers.filter(({ status }) => status !== 'COMPLETED')
      : peers,
    ['startedAt'],
    ['desc']
  );

  return (
    <Chat>
      <Chat.Sidebar>
        {isSuccess && filteredPeers.length === 0 && (
          <div
            className={clsx([
              'flex flex-col justify-center text-center',
              'rounded-md border p-3 shadow-sm',
              'bg-green-200 text-green-900',
            ])}
            onClick={setInProgressOnly.toggle}
          >
            <div className="text-xl flex items-center justify-center gap-4 py-2">
              <RocketIcon size="20" />
              All done!
            </div>
            <div>
              There are no active assessment requests for you at the moment.
            </div>
          </div>
        )}

        {filteredPeers.map((peer) => (
          <PeerCard key={peer.id} {...peer} current={peerId === peer.id} />
        ))}

        {hasCompleted && (
          <div
            className={clsx([
              'flex items-center justify-center text-center',
              'rounded-md border p-3 lg:sticky bottom-0 shadow-lg',
              'bg-gray-50 text-gray-500 cursor-pointer',
              'hover:shadow-md hover:bg-gray-50 hover:border-gray-400 hover:text-gray-700',
            ])}
            onClick={setInProgressOnly.toggle}
          >
            {inProgressOnly ? 'Show' : 'Hide'} completed
          </div>
        )}
      </Chat.Sidebar>
      <Chat.Content>{children}</Chat.Content>
    </Chat>
  );
};

export default PeerChatLayout;
