import { PeerRole } from '@prisma/client';

import clsx from 'clsx';
import Badge from 'components/badge';
import UserCard from 'components/user-card';
import getTimeAgo from 'lib/helpers/getTimeAgo';
import type { MyPeerWithInfo } from 'lib/types/peer';
import { useRouter } from 'next/router';
import React from 'react';

const PeerCard: React.FC<MyPeerWithInfo & { current?: boolean }> = ({
  id,
  startedAt,
  finishedAt,
  discipline,
  level,
  track,
  userEmail,
  role,
  assessmentId,
  status,
  current = false,
}) => {
  const router = useRouter();

  const isManager = role === PeerRole.MANAGER;
  const isCompleted = status === 'COMPLETED';

  const onClick = () => {
    const href = isManager
      ? `/admin/assessments/${assessmentId}`
      : `/peers/${id}`;

    router.push(href);
  };

  return (
    <article
      className={clsx([
        'w-full p-3 border rounded cursor-pointer opacity-80',
        'hover:shadow-md hover:opacity-100',
        isCompleted ? 'bg-gray-50' : 'bg-green-50',
        current && 'border-green-500 bg-green-100 border-2 opacity-100',
      ])}
      key={id}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <div>
          {isManager && <Badge variant="green">MANAGER</Badge>}
          <Badge variant={isCompleted ? 'green' : 'gray'}>{status}</Badge>
        </div>

        <div className="text-xs text-right">
          {finishedAt
            ? `Completed ${getTimeAgo(finishedAt)}`
            : `Created ${getTimeAgo(startedAt)}`}
        </div>
      </div>

      <h4 className="text-md mt-2 flex gap-2">
        {discipline && `${discipline}. `}
        {level} /<div className="mb-3 text-gray-500">{track}</div>
      </h4>

      <UserCard email={userEmail} />
    </article>
  );
};

export default PeerCard;
