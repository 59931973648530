import pluralize from './pluralize';

const timeIntervals = [
  { label: 'year', seconds: 60 * 60 * 24 * 365 },
  { label: 'month', seconds: 60 * 60 * 24 * 30 },
  { label: 'week', seconds: 60 * 60 * 24 * 7 },
  { label: 'day', seconds: 60 * 60 * 24 },
  { label: 'hour', seconds: 60 * 60 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 },
];

const getTimeAgo = (date: Date) => {
  const today = new Date();
  const sinceDate = new Date(date);
  const diff = Math.floor((today.getTime() - sinceDate.getTime()) / 1000);

  if (diff < 15) {
    return 'just now';
  }

  const interval = timeIntervals.find((i) => i.seconds < diff);
  if (!interval) {
    return 'a long time ago';
  }

  const count = Math.floor(diff / interval.seconds);
  return `${pluralize(count, interval.label)} ago`;
};

export default getTimeAgo;
