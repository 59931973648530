import type { NextPage } from 'next';
import { trpc } from 'utils/trpc';
import Chat from 'components/chat';
import PeerChatLayout from 'components/layouts/peer-chat-layout';
import { ArrowLeft, ArrowUp } from 'lucide-react';

const Dashboard: NextPage = () => {
  const { data: peers = [] } = trpc.user.peers.useQuery();

  const unfinishedPeer = peers.find(({ finishedAt }) => !finishedAt);

  return (
    <PeerChatLayout>
      <Chat.BotMessage>
        <b>Hey there!</b>
        <br />
        Welcome to internal assessments tool!
        <br /> I am your personal assistant. I will help you to get started.
      </Chat.BotMessage>

      <Chat.BotMessage>
        <div className="flex flex-wrap gap-2 items-center">
          {unfinishedPeer ? (
            <>
              <ArrowLeft className="text-xl hidden md:block" />
              <ArrowUp className="text-xl block md:hidden" />
              <b>You have active assessment requests.</b>
              Please select one to start.
            </>
          ) : (
            <>
              There are no assessment requests for you at the moment. You will
              be notified when you have one. Please check back later.
            </>
          )}
        </div>
      </Chat.BotMessage>
    </PeerChatLayout>
  );
};

export default Dashboard;
