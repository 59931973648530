import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export type BadgeProps = PropsWithChildren<{
  variant: 'green' | 'gray' | 'yellow' | 'sky' | 'blue' | 'teal';
  className?: string;
}>;

const variants = {
  green: 'bg-green-200 text-green-800',
  gray: 'bg-gray-200 text-gray-800',
  yellow: 'bg-yellow-200 text-yellow-800',
  sky: 'bg-sky-200 text-sky-800',
  blue: 'bg-blue-200 text-blue-800',
  teal: 'bg-teal-200 text-teal-800',
};

const Badge: React.FC<BadgeProps> = ({
  children,
  variant,
  className,
  ...props
}) => {
  return (
    <span
      className={clsx([
        'inline-block w-min text-xs font-semibold mr-2 px-2.5 py-0.5 rounded whitespace-nowrap uppercase',
        variants[variant],
        className,
      ])}
      {...props}
    >
      {children}
    </span>
  );
};

export default Badge;
